<template>
  <div class="case">
    <Header :active="active" />
    <div class="course_training">
      <div class="title">
        <div class="left">
          <div class="icon">
            <img src="@/assets/imgs/p1_16.png" alt="" />
          </div>
          <div class="name">公司活动</div>
        </div>
      </div>

      <div class="course_list">
        <div
          class="course_item"
          v-for="(item, key) in activitysList"
          :key="key"
          v-if="key < caseShowNum"
          @click="goLive(item.id, 'lookBack', item.isinside)"
        >
          <div class="img_box">
            <el-image class="big_img" :src="item.coverimg" >
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
            <div class="status">
              <img
                v-if="item.subscribe == 1"
                src="@/assets/imgs/p1_18.png"
                alt=""
              />
              <img
                v-if="item.subscribe == 0"
                @click.stop="yuyueClick(item.id)"
                src="@/assets/imgs/p1_19.png"
                alt=""
              />
              <img
                v-if="item.subscribe == 3"
                src="@/assets/imgs/p1_17.png"
                alt=""
              />
            </div>
          </div>
          <div class="s_name text_2_hidden">{{ item.title }}</div>
          <div class="time">{{ item.date }}</div>
        </div>
      </div>

      <div
        class="loadMore"
        @click="loadMore('case')"
        v-if="caseShowNum < activitysList.length"
      >
        LOAD MORE
      </div>

    </div>
    <subscribeLayer
      :subscribeLayerShow="subscribeLayerShow"
      @onSubscribeLayerShow="onSubscribeLayerShow"
    />
  </div>
</template>

<script>
import Header from "@/components/header";
import api from "@/api/serves/index";
import subscribeLayer from "@/components/subscribeLayer";
export default {
  name: "moreActivity",
  components: {
    Header,
    subscribeLayer,
  },
  data() {
    return {
      active: "lookBack",
      activitysList: [],
      caseShowNum: 8,
      subscribeLayerShow: false,
    };
  },
  watch: {
    $route(val) {
      this.active = "lookBack";
      this.getVideoArr();
    },

  },
  mounted() {
    this.getVideoArr();
  },
  methods: {
    getVideoArr() {
      api.getVideoLives().then((res) => {
        this.activitysList = res.activitys;
      });
    },
    loadMore(type) {
      this.caseShowNum += 8;
    },
    goLive(id, path, isinside) {
      var userInfo = this.$store.state.user;
      if (userInfo.inside != 1 && isinside == 1) {
        this.$message({
          message: "该视频仅限内部员工观看",
          type: "warning",
        });
        return;
      }
      let self = this;
      this.$router.push({
        path,
        query: { id, navActive: self.active },
      });
    },
    onSubscribeLayerShow() {
      this.subscribeLayerShow = false;
    },
    yuyueClick(liveid) {
      api.subscribe({ liveid }).then((res) => {
        this.subscribeLayerShow = true;
        this.getVideoArr();
      });
    },
  },
 
};
</script>

<style lang="less" scoped>
@skyBlue: #20a5e3;
@sBlue: #005aa4;
.case {
  background: #f3f3f3;
}

.course_training {
  padding: 200px 0 100px;
  width: 1250px;
  margin: 0 auto;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;

    .left {
      display: flex;
      font-size: 36px;
      .icon {
        display: flex;
        align-items: center;
        border-bottom: 4px solid @skyBlue;
      }

      .name {
        margin-left: 10px;
        padding: 0 10px;
        border-bottom: 4px solid @sBlue;
      }

      div {
        padding-bottom: 10px !important;
      }
    }

    .right {
      font-size: 24px;
      color: #666666;
      cursor: pointer;
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: #c7c7c7;
      position: absolute;
      bottom: 1px;
      z-index: -1;
    }
  }

  .course_list {
    margin-top: 58px;
    display: flex;
    flex-wrap: wrap;

    .course_item {
      width: 300px;
      margin-right: 16px;
      margin-bottom: 50px;
      cursor: pointer;
      position: relative;
      padding-bottom: 30px;

      .img_box {
        position: relative;
        border-radius: 10px;
        overflow: hidden;

        .big_img {
          width: 100%;
          height: 169px;
          transition: 0.5s all;
          border-radius: 10px;
          .image-slot {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #c0c4cc;
            background: #f5f7fa;
            height: 169px;
            border-radius: 10px;
          }
          &:hover {
            transform: scale(1.2);
          }
        }

        .status {
          position: absolute;
          bottom: 10px;
          left: 20px;
        }
      }

      .s_name {
        margin-top: 20px;
        font-size: 24px;
      }

      .time {
        position: absolute;
        top: 260px;
        font-size: 18px;
        margin-top: 4px;
        color: #999999;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }

  .loadMore {
    margin: 70px auto;
    width: 230px;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    color: #666666;
    border: 1px solid #666666;
    cursor: pointer;
  }
}
</style>